@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "./styles/colors.scss";
@import "./styles/style.scss";
@import "./styles/fonts.scss";

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.min.css";
@import "~react-checkbox-tree/lib/react-checkbox-tree.css";