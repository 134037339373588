$light-green: #C2F94B;
$dark-green: #272F31;
$dark-blue:  #22293F;
$light-pink: #DB99EE;
$light-blue: #87D7EA;

$body-bg: #fff;
$body-color: $black;
$link-color: $black;

$border-width: 2px;
$border-color: #5D6365;

$card-bg: transparent;
$card-cap-bg: transparent;
$card-border-color: #e6e6e6;
$card-border-width: 0;
$card-border-radius: 0;
$card-hover-color:          rgba($white, .7);
$card-active-color:         rgba($white, .9);
$card-spacer-x: 0;

$navbar-light-color:                rgba($white, .55);
$navbar-light-hover-color:          rgba($white, .7);
$navbar-light-active-color:         rgba($white, .9);
$navbar-light-disabled-color:       rgba($white, .3);

$sidebar-bg-color: $dark-green;
$sidebar-border-color: $dark-green;
$sidebar-item-border-color: #f2f4f6;

$topbar-bg-color: transparent;
$topbar-border-color: #edeff2;

$toast-background-color:            rgba($white, 1);
$toast-header-background-color:     rgba($white, 1);

$form-check-input-width:  2em;
$form-switch-width:       4em;
$form-check-input-border: 2px solid #5D6365;

$input-bg: transparent;
$input-border-color: #5D6365;
$input-border-width: 2px;
$input-group-addon-border-color: #5D6365;

$border-radius: 20px;
$input-border-radius: 30px;
$btn-border-radius: 30px;
$btn-border-radius-lg: 30px;
$form-floating-padding-x: 30px;
$btn-padding-y-lg: 18px;
$alert-border-radius: 30px;
$link-color: $light-green;
$link-hover-color: #9ecb3d;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $light-green;
$navbar-dark-active-color: $light-green;
$nav-link-font-size: 18px;
$nav-link-font-weight: 700;

$modal-header-border-color: #e6e6e6;
$modal-footer-border-color: #e6e6e6;

.fw-medium {
  font-weight: 500;
};

$custom-colors: (
  "primary": $light-green,
  "dark-blue": $dark-blue,
  "light-pink": $light-pink,
  "light-blue": $light-blue,
  "dark-green": $dark-green,
);