* {
	font-family: "Satoshi";
}

body {
	margin: 0;
	padding: 0;
	background-color: white !important;
}

#login-container {
	background-color: #272f31;
	position: relative;
}
#login-background {
	position: absolute;
	inset: 0;
	overflow: hidden;
}
#login-background1 {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	animation: login-background1 ease-out both;
	animation-duration: 1000ms;
}
#login-background2 {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	animation: login-background2 ease-out both;
	animation-duration: 1000ms;
}
#login-logo {
	animation: login-logo ease-out both;
	animation-duration: 300ms;
	animation-delay: 400ms;
}
.login-form {
	animation: login-form ease-out both;
	animation-duration: 300ms;
	animation-delay: 600ms;
}
.recuperar-form {
	animation: login-form ease-out both;
	animation-duration: 300ms;
}
@keyframes login-background1 {
	0% {
		opacity: 0;
		transform: translateX(20%);
	}
	50% {
		opacity: 1;
		transform: translateX(0%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes login-background2 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes login-logo {
	0% {
		opacity: 0;
		transform: translateX(-20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes login-form {
	0% {
		opacity: 0;
		transform: translateX(-20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

.input-login > label {
	color: white !important;
}

.input-login > input {
	background-color: #272f31 !important;
	color: white !important;
	border-color: #e9f9c7 !important;
	box-shadow: none !important;
}
.input-login > input:disabled {
	background-color: #2d3639 !important;
	color: $secondary !important;
}
.input-login > label {
	color: $secondary !important;
}
.input-login.floating-date-picker input {
	background-color: #272f31 !important;
	color: white !important;
	border-color: #e9f9c7 !important;
	box-shadow: none !important;
}
.input-login.btn {
	border-color: #e9f9c7 !important;
	border-width: 2px;
}

/* Change the white to any color */
.input-login input:-webkit-autofill,
.input-login input:-webkit-autofill:hover,
.input-login input:-webkit-autofill:focus,
.input-login input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #272f31 inset !important;
	-webkit-text-fill-color: white;
	transition: all 0s 50000s;
}

#sidebar {
	min-height: 100%;
	background-color: $sidebar-bg-color !important;
	background-image: url("../../public/sidebar_background.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom;
}

#sidebar .navbar-brand img {
	max-height: 40px;
}

#sidebar hr {
	opacity: 0;
	border-top-width: 2px;
	border-top-color: $sidebar-border-color;
}

#sidebar-user-dropdown > div {
	width: 32px;
	height: 32px;
	overflow: "hidden";
}

#sidebar-user-dropdown > div > img {
	width: 32px;
	height: 32px;
	object-fit: cover;
}

.topbar {
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: $topbar-border-color !important;
	background-color: $topbar-bg-color;
}

.form-floating > .form-control::placeholder {
	color: revert !important;
}

.form-floating > .form-control:not(:focus)::placeholder {
	color: transparent !important;
}

.form-file {
	cursor: pointer;
}
.form-file:hover {
	background-color: #f8f9fa;
}

.list-card {
	border-bottom-width: 1px !important;
}

.list-card:hover {
	background-color: $card-hover-color;
}

.list-card:active {
	background-color: $card-active-color;
}

.skeleton::after {
	animation: skeleton-loading 2s linear infinite alternate;
}

.skeleton-text {
	visibility: hidden;
	position: relative;
}

.skeleton-text::after {
	visibility: visible;
	position: absolute;
	content: " ";
	width: 90%;
	height: 90%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: $border-radius;
}

.skeleton-text-title::after {
	width: 70%;
}

.skeleton-text-content::after {
	width: 90%;
}

.skeleton-button {
	visibility: hidden;
	position: relative;
}

.skeleton-button::after {
	visibility: visible;
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: $border-radius;
}

.skeleton-badge {
	visibility: hidden;
	position: relative;
}
.skeleton-badge::after {
	visibility: visible;
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: $border-radius;
}

@keyframes skeleton-loading {
	0% {
		background-color: rgba($black, 0.05);
	}

	20% {
		background-color: rgba($black, 0.05);
	}

	50% {
		background-color: rgba($black, 0.1);
	}

	80% {
		background-color: rgba($black, 0.05);
	}

	100% {
		background-color: rgba($black, 0.05);
	}
}

.tox-tinymce {
	border-width: $border-width !important;
	border-color: $border-color !important;
	border-radius: $border-radius !important;
}

.tox-tinymce--disabled {
	border-color: $border-color !important;
}

.tox-tinymce.focus {
	border-color: #86b7fe !important;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.tox-tinymce--disabled .tox-edit-area__iframe,
.tox-tinymce--disabled .tox-statusbar,
.tox-tinymce--disabled .tox-editor-header {
	background-color: $input-disabled-bg !important;
}

.tox-tbtn--disabled .tox-toolbar__primary {
	background-color: $input-disabled-bg !important;
}

.tox .tox-dialog--width-lg {
	max-width: 400px !important;
}

.react-datepicker {
	border-width: $border-width !important;
	border-color: $border-color !important;
	border-radius: $border-radius !important;
}

.react-datepicker__header {
	background-color: $card-bg;
}

.react-datepicker__navigation {
	margin-top: $spacer * 0.5;
}

.react-datepicker__header {
	border-bottom-color: $border-color;
}

.react-datepicker__current-month {
	font-weight: normal;
}

.react-datepicker__navigation-icon::before {
	top: 12px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	width: 2.1rem;
}

.input-group .react-datepicker-wrapper {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}

.input-group .react-datepicker-wrapper .form-control {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rct-icon {
	min-width: 24px;
}

.react-checkbox-tree ol ol {
	padding-left: 32px;
}

.form-control.disabled {
	background-color: $input-disabled-bg !important;
}

.line-1 {
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.text-underline {
	border-bottom: 2px solid $light-green;
}

.floating-select > label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.floating-date-picker label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.floating-date-picker .react-datepicker__input-container > input {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
	padding: 1rem 30px;
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	top: -$border-width;
	border-bottom-color: $border-color;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
	border-bottom-color: white;
}
.react-datepicker__day--in-selecting-range {
	background-color: #e9f9c7 !important;
}

.dropdown-no-caret::after {
	display: none !important;
}

.card-with-border {
	border-color: #e6e6e6 !important;
	border-width: 2px !important;
	border-radius: 20px !important;
}
.card-with-border .card-body {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

.tab-option {
	color: #6c757d;
	border-bottom-style: solid;
	border-bottom-color: #a9d941;
	border-bottom-width: 0;
	cursor: pointer;
}
.tab-option.active {
	color: #272f31;
	border-bottom-width: 4px;
}
.tab-option:hover {
	color: #272f31;
}
.input-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.parent-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

// $navbar-light-color:                rgba($white, .55);
// $navbar-light-hover-color:          rgba($white, .7);
// $navbar-light-active-color:         rgba($white, .9);
// $navbar-light-disabled-color:       rgba($white, .3);
